<template>
  <v-app>
    <navigation />
    <v-main v-if="!isLoading">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-icon left>mdi-account-multiple-outline</v-icon>
                Manage users
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left> mdi-plus </v-icon>
                      Add User
                    </v-btn>
                  </template>
                  <v-form
                      class="mt-5"
                      ref="formUser"
                      v-model="formUserValid"
                      lazy-validation
                    >
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-if="editedIndex === -1"
                                v-model="editedItem.email"
                                :rules="[v => !!v || 'This feild is required']"
                                validate-on-blur
                                label="Email"
                              >
                              </v-text-field>
                              <template v-if="editedIndex > -1">
                                {{ editedItem.email }}
                              </template>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-checkbox
                              v-model="editedItem.isAdmin"
                              label="Is Admin ?"
                            >
                            </v-checkbox>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-card-title>
              <v-card-text>
                <v-dialog
                  v-model="dialogDelete"
                  max-width="500px"
                  persistent
                  disable-pagination
                >
                  <v-card>
                    <v-card-title class="text-h5"
                      >Are you sure you want to delete this item?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-data-table
                  :headers="headers"
                  :items="users"
                  hide-default-footer
                >
                  <template v-slot:item.isAdmin="{ item }">
                    {{ item.isAdmin ? "Yes" : "No" }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <template v-if="!item.isCurrentUser">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-icon left>mdi-account-remove</v-icon>
                Delete my account
              </v-card-title>
              <v-card-text>
                <v-dialog v-model="dialogDeleteAccount" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block color="error" v-bind="attrs" v-on="on">
                      Delete my account {{ $route.params.accountName }} and its data
                    </v-btn>
                  </template>
                  <v-card>
                    <v-form
                      class="mt-5"
                      ref="formDeleteAccount"
                      v-model="deleteAccountValid"
                      lazy-validation
                    >
                      <v-card-title>
                        <span class="text-h5"
                          >Delete my account
                          {{ $route.params.accountName }}</span
                        >
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <b
                              >Are you sure? This action cannot be undone. This will permanently delete your data related to your account in this application.</b
                            >
                            <br>
                            Please type the name of your account <b>{{ $route.params.accountName }}</b> to confirm.
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="accountName"
                              label="Account name"
                              required
                              :rules="[
                                (v) =>
                                  v == this.$route.params.accountName ||
                                  'The name that you entered doesn\'t match your account name',
                              ]"
                              validate-on-blur
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error darken-1"
                          text
                          @click="closeDeleteAccount()"
                        >
                          Cancel
                        </v-btn>
                        <v-btn color="error darken-1" @click="deleteAccount()">
                          Confirm
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import AdminService from "@/services/AdminService";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "Admin",
  data: () => ({
    headers: [
      { text: "Email", value: "email" },
      { text: "Is Admin ?", value: "isAdmin" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    users: [],
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    dialogDeleteAccount: false,
    deleteAccountValid: true,
    accountName: "",
    auditService: null,
    nbAudits: 0,
    isLoading: true,
    formUserValid: true,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New user" : "Edit user";
    },
  },
  components: {
    Navigation,
  },
  async mounted() {
    const accountName = this.$route.params.accountName;
    this.adminService = new AdminService(accountName);
    this.users = await this.adminService.getUsers();

    this.isLoading = false;
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = cloneDeep(item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.adminService.deleteUser(this.editedItem);
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if(this.$refs.formUser.validate()){
        if (this.editedIndex > -1) {
          await this.adminService.updateUser(this.editedItem);
          Object.assign(this.users[this.editedIndex], this.editedItem);
        } else {
          await this.adminService.addUser(this.editedItem);
          this.users.push(this.editedItem);
        }
        this.close();
      }
    },
    async deleteAccount() {
      const formIsValid = this.$refs.formDeleteAccount.validate();
      if (!formIsValid) return;

      const response = await this.adminService.deleteAccount();

      if (response.ok) {
        this.$router.push("/lobby");
      }
    },

    closeDeleteAccount() {
      this.dialogDeleteAccount = false;
      this.accountName = "";
    },
  },
};
</script>
