<template>
  <v-app>
    <Navigation v-if="!isAnonymous"/>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <div class="d-flex">
              <v-icon light>mdi-format-list-bulleted</v-icon>
              <h1 class="ml-3">Conditions of use</h1>
            </div>
          </v-col>

          <v-col class="text-right" cols="6">
            <div v-if="isAnonymous">
                <v-btn class="ma-2" color="secondary" to="/">
                    <v-icon dark left>
                        mdi-arrow-left
                    </v-icon>
                    Back
                </v-btn>
            </div>
          </v-col>

        </v-row>
        <v-row><v-divider></v-divider></v-row>

        <v-row style="max-height: 75vh; overflow-y: auto;" ref="scroll-conditions">
          <v-col cols="12">
            <div>
              <p>These Terms and Conditions (“Terms”, “Terms of Use“, “Terms and Conditions”, “Terms of Service”) govern your relationship with the app service for Microsoft Azure Cloud (the “Service”) operated by Expertime (“us”, “we”, or “our”).</p>
              <p><strong>Please read these Terms and Conditions carefully before using the Service.</strong></p>
              <p>
                Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
                You must be of an age of majority in your state or province of residence. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the Terms then you may not access the Service.
              </p>
            </div>
            <div>
              <p><strong>1. Purchase And Subscriptions</strong></p>
              <ul>
                <li>
                  <p>
                    The Service is billed on a subscription basis (“Subscription(s)”). On this Subscription basis, you will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on an annual basis.
                  </p>
                </li>
                <li>
                  <p>
                    Your Subscription must be cancelled 60 (sixty) days prior to end date if you do not want to renew it. Otherwise, at the end of each Subscription Term, your Subscription will automatically renew under the exact same conditions. You may cancel your Subscription renewal by contacting the Expertime customer support team or your usual contact.
                    The Subscription term starts with the registration of the customer data in the Expertime license center.
                  </p>
                </li>
                <li>
                  <p>
                    Registration is done either by Expertime or by downloading the software, whichever occurs first.
                    If the customer starts a free trial and then decides to purchase the product, the license is changed from trial to active. This change date is the start date of the Subscription term.
                  </p>
                </li>
                <li>
                  <p>
                    A valid payment method, including credit card, is required to process the payment for your Subscription. You shall provide Expertime with accurate and complete billing information including full name, address, state, country, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Expertime to charge all Subscription fees incurred through your account to any such payment instruments.
                    Should automatic billing fail to occur for any reason, Expertime will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
                  </p>
                </li>
              </ul>
               <p><strong>2. Free Changes</strong></p>
               <ul>
                <li>
                  <p>
                    Expertime, in its sole discretion and at any time, may modify the Purchasing and Subscription fees. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
                  </p>
                </li>
                <li>
                  <p>
                    Expertime will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.
                  </p>
                </li>
                <li>
                  <p>
                    Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
                  </p>
                </li>
               </ul>
               <p><strong>3. Refunds</strong></p>
               <ul>
                <li>
                  <p>
                    Except when required by law, paid Purchasing and Subscription fees are non-refundable.
                  </p>
                </li>
               </ul>
               <p><strong>4. Termination</strong></p>
               <ul>
                <li>
                  <p>
                    We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                  </p>
                </li>
                <li>
                  <p>
                    Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
                  </p>
                </li>
               </ul>
               <p><strong>5. Limitation of liability</strong></p>
               <ul>
                <li>
                  <p>
                    In no event shall Expertime, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
                  </p>
                </li>
               </ul>
               <p><strong>6. Disclaimer</strong></p>
               <ul>
                <li>
                  <p>
                    Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
                  </p>
                </li>
                <li>
                  <p>
                    Expertime, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
                  </p>
                </li>
               </ul>
               <p><strong>7. Free Trial</strong></p>
               <ul>
                <li>
                  <p>
                    Expertime may, at its sole discretion, offer a Subscription with a free trial for a limited time period (“Free Trial”).
                  </p>
                </li>
                <li>
                  <p>
                    You may not be required to enter any billing information in order to sign up for the Free Trial.
                  </p>
                </li>
                <li>
                  <p>
                    At any time and without notice, Expertime reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.
                  </p>
                </li>
               </ul>
               <p><strong>8. Intellectual property</strong></p>
               <ul>
                <li>
                  <p>
                    The Service and its original content, features and functionality are and will remain the exclusive property of Expertime. The Service is protected by copyright, trademark, and other laws of both France and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of Expertime.
                  </p>
                </li>
               </ul>
               <p><strong>9. Data protection</strong></p>
               <ul>
                <li>
                  <p>
                    Data management and protection are a priority for Expertime. We do not store, process, or resell any personal data which remains your exclusive property.
                    The Service may display some of your Microsoft Azure subscription information (such as Tenant ID, Client ID and Client Secret) to provide you some specific features in the Audits section with the permissions granted to the application when you install it.
                  </p>
                </li>
               </ul>
               <p><strong>10. Changes</strong></p>
               <ul>
                <li>
                  <p>
                    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                    By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
                  </p>
                </li>
               </ul>
               <p><strong>11.	Governing Law</strong></p>
               <ul>
                <li>
                  <p>
                    These Terms shall be governed and construed in accordance with the laws of France, without regard to its conflict of law provisions.
                    Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
                  </p>
                </li>
               </ul>
               <p><strong>12. Links To Other Websites</strong></p>
               <ul>
                <li>
                  <p>
                    Our Service may contain links to third-party websites or services that are not owned or controlled by Expertime.
                  </p>
                </li>
                <li>
                  <p>
                    Expertime has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third–party websites or services. You further acknowledge and agree that Expertime shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.
                  </p>
                </li>
                <li>
                  <p>
                    We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.
                  </p>
                </li>
               </ul>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn v-if="!agreed" @click="agree" class="info my-2">I agree with these conditions</v-btn>
        </v-row>
      </v-container>
    </v-main>
    <Footer v-if="!isAnonymous"/>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import AccountService from "@/services/AccountService";
import useAuthStore from "@/stores/authStore";

export default {
  name: "CGU",
  components: {
    Navigation,
    Footer,
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore: authStore,
    };
  },
  data:()=>({
    agreed: true,
    accountService: null,
    isAnonymous: false
  }),
  mounted(){
    setTimeout(()=>{
      if (this?.authStore?.me?.agreed != undefined){
        this.agreed = this.authStore.me.agreed;
      }
    },1000)

    this.accountService = new AccountService(this.$route.params.accountName);
    this.isAnonymous = this.$router.history.current.path == "/cgu";
  },
  methods: {
    async agree(){
      await this.accountService.agreeConditions();
      this.agreed = true;

      this.$router.push("audits")
    }
  }
};
</script>

<style scoped>
ul:not(.slick-dots) li {
    list-style: none;
    position: relative;
}

ul:not(.slick-dots) li:before {
    content: "";
    display: block;
    left: -20px;
    top: 7px;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 0 4px;
    background-color: #2987f8;
}
</style>>
