<template>
  <v-app>
    <Navigation v-if="!isAnonymous"/>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <div class="d-flex">
              <v-icon light>mdi-format-list-bulleted</v-icon>
              <h1 class="ml-3">Privacy policy</h1>
            </div>
          </v-col>

          <v-col class="text-right" cols="6">
            <div v-if="isAnonymous">
                <v-btn class="ma-2" color="secondary" to="/">
                    <v-icon dark left>
                        mdi-arrow-left
                    </v-icon>
                    Back
                </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row><v-divider></v-divider></v-row>

        <v-row style="max-height: 75vh; overflow-y: auto;" ref="scroll-conditions">
          <v-col cols="12">
            <div>
              <p><strong>1. Definitions and scope</strong></p>
              <p><strong>1.1. Definitions</strong></p>
              <ul>
                <li>
                  <p>
                    1.	Cookie(s): these are small data files sent to the Internet browser you use by the Web Application of the Software Editor you are visiting. They have many different purposes, but mainly, Cookies have the role of facilitating your navigation on a Web Application by remembering, for example, your user preferences, or for the purpose of improving the user experience. These Cookies are deposited by your browser on the terminal (computer, tablet, smartphone ...) that you use and can only be read and / or modified by their issuer. The Cookies set up on this Web Application may be set up either by the Software Editor or by Partners, Third Parties or Subcontractors. For more information on Cookies, refer to the Cookie Policy via the link available at the bottom of each page of this Site.
                  </p>
                </li>
                <li>
                  <p>
                    2.	Navigation data: refers to all data that relates to a given terminal (computer, smartphone, tablet, etc.), even if the Software Editor does not know directly which terminal is used or who the User is at a given time. Under the Navigation Data collected, the following data may be collected: identification and content of a Cookies file stored by the Software Editor or a Partner on the terminal, the IP address of the terminal connected to the Site, the date, time and duration of connection of a terminal to an element of the Service, the Internet address of the page from which the terminal accessing the Services originates,  the type of operating system of the terminal (Windows, MacOs, Linux, Beos, etc ...), the type and version of the browser software used by the terminal (Internet Explorer, Edge, Firefox, Chrome, Safari, Opera ...), the language of use of the browser software used by the terminal, the characteristics of the pages consulted (name of documents, viewing times ...).
                  </p>
                </li>
                <li>
                  <p>
                    3.	Personal Data: refers to any information relating to an identified or identifiable natural person, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, an online identifier, etc. Regarding our Site, it refers to all data that relates to the User alone at a given time, regardless of the terminal used.
                  </p>
                </li>
                <li>
                  <p>
                    4.	Software Editor: refers to the company EXPERTIME, SAS with a capital of 112,195.00 Euros registered in the Versailles Trade and Companies Register under the SIRET number 448 071 878 000 38 whose registered office is located at 80 Bis Avenue du Général Leclerc at 78220 VIROFLAY, France.
                  </p>
                </li>
                <li>
                  <p>
                    5.	Partner: refers to any company, other than a Subcontractor, selected by the Software Editor, with which the Software Editor has contracted. The Partners entitled to receive and use your Personal Data or Your Navigation Data, if the User has consented to this, are listed in the appendix to this Policy.
                  </p>
                </li>
                <li>
                  <p>
                    6.	Service: refers to the Software Editor's service accessible via the corresponding Site.
                  </p>
                </li>
                <li>
                  <p>
                    7.	Web Application: refers to Software as a Service linked to the following URL address and/or any address that would be added to or replaced by it: www.greenops4cloud.com.
                  </p>
                </li>
                <li>
                  <p>
                    8.	User: refers to the user of the Web Application made available by the Software Editor.
                  </p>
                </li>
                <li>
                  <p>
                    9.	Subcontractor: refers to any company that may be required to process your Personal Data on behalf of the Software Editor, in accordance with the instructions given by the latter.
                  </p>
                </li>
              </ul>
               <p><strong>1.2. Scope</strong></p>
               <ul>
                <li>
                  <p>
                    The Personal Data Protection Policy applies as soon as the User accesses the Services of the Software Editor's Site. However, it does not apply to the sites of our Partners or those of Third Party.
                  </p>
                </li>
               </ul>
              <p><strong>2. Contacts and contact details</strong></p>
              <p><strong>2.1. Data Controller</strong></p>
              <ul>
                <li>
                  <p>
                    For any information or exercise of your rights Informatique et Libertés on the processing of Personal Data or Navigation Data managed by the Software Editor, you can contact the data controller at the contact details below:
                  </p>
                </li>
                <li>
                  <p>
                    1.	By this form here
                  </p>
                </li>
                <li>
                  <p>
                    2.	Or by email to the address greenops4cloud@expertime.com (attaching a copy of your identity document in case of exercise of your rights)
                  </p>
                </li>
                <li>
                  <p>
                    3.	Or by post (attaching a copy of your identity document in case of exercise of your rights) to the following address: EXPERTIME, IT Department, 80 Bis Avenue du Général Leclerc, 78220 VIROFLAY, France.
                  </p>
                </li>
               </ul>
              <p><strong>2.2. Data Protection Officer</strong></p>
              <ul>
                <li>
                  <p>
                    For any information or exercise of your rights Informatique et Libertés on the processing of Personal Data or Navigation Data managed by the Software Editor or any information related to the General Data Protection Regulation, you can contact the Data Protection Officer (DPO) at the contact details below:
                  </p>
                </li>
                <li>
                  <p>
                    1.	By this form here
                  </p>
                </li>
                <li>
                  <p>
                    2.	Or by email to the address dpo@expertime.com (attaching a copy of your identity document in case of exercise of your rights)
                  </p>
                </li>
                <li>
                  <p>
                    3.	Or by post (attaching a copy of your identity document in case of exercise of your rights) to the following address: EXPERTIME, Data Protection Officer, 80 Bis Avenue du Général Leclerc, 78220 VIROFLAY, France.
                  </p>
                </li>
               </ul>
              <p><strong>3. Data collection</strong></p>
              <p>
                The Software Editor collects and processes the User's data with his consent and this, to execute the contract concluded with the latter (acceptance of the general conditions of use of the services) and on the basis of the legitimate interest of the Software Editor. When subscribing to certain additional services accessible on our Web Application, the User will be informed of the collection and processing of his Personal Data through an information notice. The Software Editor does not collect information through the implementation of Cookies, aggregated and anonymous on the User's Navigation Data.
              </p>
              <p><strong>4. Data collected</strong></p>
              <p><strong>4.1. Direct collection from the User</strong></p>
              <p><strong>4.1.1. Subscription to the GreenOps4Cloud Service</strong></p>
              <p>
                 The data collected is as follows:
              </p>
              <ul>
                <li>
                  <p>
                    1.	User's Email Address
                  </p>
                </li>
              </ul>
              <p><strong>4.1.2. Request for contact from the User to the Software Editor</strong></p>
              <p>
                 The data collected is as follows:
              </p>
              <ul>
                <li>
                  <p>
                    1.	User's Name – Required
                  </p>
                </li>
                <li>
                  <p>
                    2.	First name of the User – Mandatory
                  </p>
                </li>
                <li>
                  <p>
                    3.	User's Company – Mandatory
                  </p>
                </li>
                <li>
                  <p>
                    4.	User Function/Position – Optional
                  </p>
                </li>
                <li>
                  <p>
                   5.	User's phone number – Optional
                  </p>
                </li>
                <li>
                  <p>
                    6.	User's Email Address – Required
                  </p>
                </li>
                <li>
                  <p>
                    7.	Message from the User to the attention of the Software Editor – Mandatory
                  </p>
                </li>
              </ul>
              <p><strong>5. Purposes of processing</strong></p>
              <p><strong>5.1. Processing carried out on the legal basis of the contract between the Software Editor and the User</strong></p>
                <p>
                 The Personal Data that the Software Editor collects directly from the User is based on the legal basis of the contract between the two parties, namely to provide the Service(s):
                 </p>
              <ul>
                <li>
                  <p>
                    1.	Software Editor's Web Application account creation and link to an Azure tenant in order to run assessment
                  </p>
                </li>
                <li>
                  <p>
                    2.	Software Editor's contact form
                  </p>
                </li>
              </ul>
              <p><strong>5.2. Processing carried out on the legal basis of the Legitimate Interest of the Software Editor</strong></p>
                <p>
                  The Personal Data that the Software Editor collects directly from the User, in particular when using our Services,  may be processed for the following purposes:
                 </p>
              <ul>
                <li>
                  <p>
                    1.	Ensure the security of the Software Editor's IT infrastructures by detecting/tracing any attempt at malicious intent, computer intrusion or violation of the General Terms and Conditions of Use
                  </p>
                </li>
              </ul>
              <p><strong>5.3. Processing carried out on the basis of fulfilling the Legal Obligations of the Software Editor</strong></p>
                <p>
                  The Personal Data that the Software Editor collects directly from the User when using the Site Services as well as the Navigation Data collected via the deposit of Cookies and other tracers on the User's terminal may be processed for the following purposes:
                 </p>
              <ul>
                <li>
                  <p>
                    1.	Respond to any requests from Third Parties authorized by law to ask the Software Editor to communicate the User's Navigation Data and/or Personal Data.
                  </p>
                </li>
              </ul>
              <p><strong>6. Data retention</strong></p>
              <p><strong>6.1. Legal duration</strong></p>
                <p>
                  With the exception of the categories of Personal Data referred to in Articles 6.2 to 6.4, Personal Data and Navigation Data are kept for the following periods:
                 </p>
              <ul>
                <li>
                  <p>
                    1.	If the User is an existing customer or free user in relation to the Software Editor, the data is kept as long as the contractual and/or commercial relationship between the Software Editor and the User lasts.
                  </p>
                </li>
              </ul>
              <p><strong>6.2. Proof of identity</strong></p>
                <p>
                  The proof of identity provided by the User in the context of the exercise of his rights are kept for a period of one (1) year from the request for the exercise of his last.
                 </p>
              <p><strong>7. Access to the User's Personal Data</strong></p>
                <p>
                  The User's Personal Data may be accessed by:
                 </p>
              <ul>
                <li>
                  <p>
                    1.	The Software Editor of the Service as data controller of the Service
                  </p>
                </li>
                <li>
                  <p>
                    2.	The Data Protection Officer as responsible for compliance related to the General Data Protection Regulation
                  </p>
                </li>
                <li>
                  <p>
                    3.	A limited staff of the Software Editor, subject to a strict obligation of confidentiality: IT Department, Sales Department
                  </p>
                </li>
                <li>
                  <p>
                    4.	The Subcontractors to whom the Software Editor calls in terms of technical services, cloud services, providers of analytical solutions...
                  </p>
                </li>
                <li>
                  <p>
                    5.	Third Parties, in limited cases and regulated by law such as in particular the judicial and/or administrative authorities or the User's Internet service provider
                  </p>
                </li>
              </ul>
              <p><strong>8. Transfer of personal data</strong></p>
                <p>
                  The Personal Data and Navigation Data collected by the Software Editor for the User are not stored on servers outside the European Union but in the Cloud (Microsoft Cloud Azure) with a main site in France and a secondary site in Western Europe, the EU Member States being subject to the same legislation in terms of the General Data Protection Regulation.
The Software Editor may use external Partners or with whom it may share the User's data for the purposes indicated in Chapter 5. Whenever the Software Editor uses external Service Providers or Subcontractors, it ensures that they have an appropriate level of protection of Personal Data.
The Software Editor may also be required by laws, court decisions and/or requests from public and governmental authorities inside or outside the User's country of residence to disclose the User's Personal Data.
The Software Editor may also disclose the User's Personal Data if it determines that, for reasons of national security, law enforcement or public importance or pursuant to the law, disclosure is necessary or appropriate.
Finally, the Software Editor may disclose the User's Personal Data if it determines in good faith that disclosure is reasonably necessary to protect its rights by using available remedies, enforce its terms and conditions, to investigate fraud or to protect its operations and users.
                 </p>
              <p><strong>9. User's Rights</strong></p>
                <p>
                  The User has a right to withdraw his consent at any time, a right to the portability of his Personal Data as well as a right of access, rectification, erasure, limitation, opposition and the right to define guidelines relating to the fate of his data in the event of death.
                 </p>
              <p><strong>9.1. General</strong></p>
                <p>
                  The User may exercise his rights by contacting the Data Controller or the Data Protection Officer as described in Chapter 2.
                 </p>
              <p><strong>9.2. Supporting documents</strong></p>
                <p>
                  Pursuant to the provisions of Decree No. 2007-451 of 25 March 2007 amending Decree No. 2005-1309 of 20 October 2005 adopted for the application of Law No. 78-17 of 6 January 1978 on Data Processing, Files and Freedoms, the request to exercise one of the User's rights must be accompanied by a photocopy of an identity document bearing his signature.
The request must also specify the address to which the response must be sent. In order to identify the User, the Software Editor invites him to indicate the email address through which he subscribed to the Services (subscription to the Newsletters ...).
The Software Editor has a period of one (1) month following receipt of the request to respond to the User. This period may be extended by two (2) months taking into account the complexity and number of requests.
These rights are not absolute: the User may exercise them within the legal framework provided and within the limits of this right. In some cases, the Software Editor may not respond favourably to the request (legal obligation...) and if this is the case, the reason(s) for this refusal will then be communicated to him.
                 </p>
              <p><strong>9.3. Right of access, rectification or erasure</strong></p>
                <p>
                  The User may access the Personal Data collected by the Software Editor directly from him to rectify, complete or delete any inaccurate information by contacting him via the contact details described in Chapter 2.
                  The User has a right to erasure of his Personal Data when:
                 </p>
              <ul>
                <li>
                  <p>
                    1.	These are no longer necessary in view of the purposes for which they were collected or processed
                  </p>
                </li>
                <li>
                  <p>
                    2.	Or if he has withdrawn his consent for this processing
                  </p>
                </li>
                <li>
                  <p>
                    3.	Or there is no other legal basis for the processing
                  </p>
                </li>
                <li>
                  <p>
                    4.	Or he objected to the treatment
                  </p>
                </li>
                <li>
                  <p>
                    5.	Or there are no compelling legitimate grounds justifying such processing
                  </p>
                </li>
                <li>
                  <p>
                    6.	Or the data must be erased to comply with a legal obligation provided for by the legislation of the European Union or its Member States
                  </p>
                </li>
                <li>
                  <p>
                    7.	Or the data was collected as part of the provision of a Service to which the User has subscribed
                  </p>
                </li>
              </ul>
              <p><strong>9.4. Right to restriction of processing</strong></p>
                <p>
                  The User has a right to obtain the limitation of processing when one of the following conditions applies:
                 </p>
              <ul>
                <li>
                  <p>
                    1.	The User disputes the accuracy of the Personal Data
                  </p>
                </li>
                <li>
                  <p>
                    2.	The processing is considered unlawful
                  </p>
                </li>
                <li>
                  <p>
                    3.	The User opposes the erasure of his Personal Data
                  </p>
                </li>
                <li>
                  <p>
                    4.	The Software Editor no longer needs the User's Personal Data but the latter deems them necessary for the establishment, exercise or defense of his rights
                  </p>
                </li>
                <li>
                  <p>
                    5.	The User opposes the processing and a check must be carried out in order to know if the legitimate reasons of the Software Editor prevail over those of the User
                  </p>
                </li>
              </ul>
              <p><strong>9.5. Right to withdraw consent</strong></p>
                <p>
                  The User has a right to withdraw his consent at any time according to the modalities described in chapter 8.
                 </p>
              <p><strong>9.6. Right to object</strong></p>
                <p>
                  The User may exercise his right to oppose the processing of his Personal Data at any time, in particular for processing related to Cookies deposited on his terminal without the required agreement from him.
                 </p>
              <p><strong>9.7. Right to complain</strong></p>
                <p>
                  The User may lodge a complaint with the competent supervisory authority, the Commission Nationale de l'Informatique et des Libertés (CNIL) via the https://www.cnil/fr/plaintes form.
                 </p>
              <p><strong>9.8. Guidelines in the event of death</strong></p>
                <p>
                  The User has the right to define guidelines relating to the fate of his Personal Data after his death. To exercise this right, the Software Editor invites him to send an email (see chapter 2) with the subject "Guidelines relating to the fate of my Personal Data after my death", with the email that made it possible to subscribe to a Service and to indicate the person or persons who can act with the Software Editor in the event of his death as well as the instructions he wishes to apply. In the event that a User dies without having left a directive after his death, the heirs of the latter must then contact the Data Protection Officer of the Software Editor.
                 </p>
              <p><strong>10. Data security</strong></p>
                <p>
                  The Software Editor uses various technical and organizational security measures to protect the Personal Data collected or used against loss, misuse, falsification or destruction. However, as the Internet is open to all, the Software Editor cannot be held responsible for the security of transmissions of Personal Data via the Internet.
If the User has reason to believe that his interaction with the Software Editor is no longer secure, he is advised to report this immediately by contacting the Data Protection Officer whose contact details appear in Chapter 2.
                 </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer v-if="!isAnonymous"/>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import AccountService from "@/services/AccountService";
import useAuthStore from "@/stores/authStore";

export default {
  name: "Privacy",
  components: {
    Navigation,
    Footer,
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore: authStore,
    };
  },
  data:()=>({
    accountService: null,
    isAnonymous: false
  }),
  mounted(){
    this.accountService = new AccountService(this.$route.params.accountName);
    this.isAnonymous = this.$router.history.current.path == "/privacy";
  }
};
</script>

<style scoped>
ul:not(.slick-dots) li {
    list-style: none;
    position: relative;
}

ul:not(.slick-dots) li:before {
    content: "";
    display: block;
    left: -20px;
    top: 7px;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 0 4px;
    background-color: #2987f8;
}
</style>>
