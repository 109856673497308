<template>
  <v-app>
    <navigation />
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-icon left>mdi-connection</v-icon>
                Service connections
                <v-spacer></v-spacer>
                <v-btn
                  class="float-right mx-2"
                  elevation="2"
                  color="success"
                  @click.stop="onClickServiceConnection(-1)"
                >
                  <v-icon left> mdi-plus </v-icon>
                  New service connection
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12">
                    <v-list dense>
                      <v-list-item-group>
                        <template v-for="(item, index) in serviceConnections">
                          <v-list-item :key="item.id">
                            <v-list-item-icon>
                              <img src="/Azure.png" />
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.name"
                                @click.stop="onClickServiceConnection(item.id)"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            v-if="index < serviceConnections.length - 1"
                            :key="index + 1000"
                          ></v-divider>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="this.nbAudits == 0 && !this.isLoading && this.serviceConnections.length > 0">
          <v-col cols="12">
            <v-alert type="info" class="ma-3 info" :value="true">
              <span class="font-weight-bold">
                     You are now ready to launch your first Audit&nbsp;
                    <router-link to="audits/new" style="text-decoration-color: white;color:white;"> Here </router-link>
              </span>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-icon left>mdi-account-convert</v-icon>
                Change your account
              </v-card-title>
              <v-card-text>
                <v-btn block color="error" to="/lobby" v-on="on">
                  Change
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-navigation-drawer v-model="drawer" style="min-width: 20vw" absolute temporary right>
        <service-connection-details
          v-bind:serviceConnectionId="selectionServiceConnectionId"
          @close-drawer="onServiceConnectionModified"
        />
      </v-navigation-drawer>
      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        right
        :color="confirmColor"
      >
        {{ confirmText }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import ServiceConnectionDetails from "@/components/Account/ServiceConnectionDetails.vue";
import ServiceConnectionsService from "../../services/ServiceConnectionsService";
import AdminService from "@/services/AdminService";
import AuditService from "@/services/AuditService";

export default {
  name: "Account",
  components: {
    Navigation,
    Footer,
    ServiceConnectionDetails,
  },
  async mounted() {
    this.serviceConnectionsService = new ServiceConnectionsService(
      this.$route.params.accountName
    );
    let response = await this.serviceConnectionsService.getServiceConnections();
    this.serviceConnections = await response.json();

    this.adminService = new AdminService(this.$route.params.accountName);

    this.auditService = new AuditService(this.$route.params.accountName);
    response = await this.auditService.getAuditsCount();
    this.nbAudits = await response.json();

    this.isLoading = false;
  },
  data: () => ({
    confirmColor: "",
    confirmText: "",
    drawer: null,
    serviceConnections: [],
    snackbar: false,
    selectionServiceConnectionId: null,
    serviceConnectionsService: null,
    nbAudits: 0,
    isLoading: true
  }),
  methods: {
    onClickServiceConnection(id) {
      this.selectionServiceConnectionId = id;
      this.drawer = !this.drawer;
    },
    async onServiceConnectionModified(isOk) {
      this.drawer = false;
      if (isOk) {
        this.confirmColor = "green";
        this.confirmText = "Success ! 🥳";
      } else {
        this.confirmText = "An error occured, please try again later";
        this.confirmColor = "red";
      }
      this.snackbar = true;

      const response =
        await this.serviceConnectionsService.getServiceConnections();
      this.serviceConnections = await response.json();
    }

  },

};
</script>
<style>
.info {
  background-color: #2987f8 !important;
}
</style>