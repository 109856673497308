import BaseService from "./BaseService";

export default class AdminService extends BaseService {
    constructor(accountName) {
        super();

        this.accountName = accountName;
        this.baseUrl = `${this.baseUrl}/${this.accountName}/admin`;
    }

    async addUser(user) {
        const response = await this.sendRequest(`${this.baseUrl}/users`, "POST", user);
        return response.text;
    }

    async getUsers() {
        const response = await this.sendRequest(`${this.baseUrl}/users`);
        return response.json();
    }

    async updateUser(user) {
        const response = await this.sendRequest(`${this.baseUrl}/users/${user.id}`, "PUT", user);
        return response.status;
    }

    async deleteUser(user) {
        const response = await this.sendRequest(`${this.baseUrl}/users/${user.id}`, "DELETE");
        return response.status;
    }

    async deleteAccount() {
        const response = await this.sendRequest(`${this.baseUrl}/account`, "DELETE");
        return response;
    }
}