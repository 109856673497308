import BaseService from "./BaseService";

export default class AuditService extends BaseService {
    async createAudit(name) {
        const response = await this.sendRequest(`${this.baseUrl}/audits`, 'POST', { name: name });
        return response;
    }

    async getAudits() {
        return await this.sendRequest(`${this.baseUrl}/audits`);
    }

    async getAuditsCount() {
        return await this.sendRequest(`${this.baseUrl}/audits/count`);
    }

    async hasAuditsInProgress() {
        const response = await this.sendRequest(`${this.baseUrl}/audits?status=New`);
        return (await response.json()).length > 0;
    }

    async getAudit(id) {
        const response = await this.sendRequest(`${this.baseUrl}/audits/${id}`);
        return response.json();
    }

    async launchAuditCalculation(auditId) {
        const response = await this.sendRequest(`${this.baseUrl}/audits/${auditId}/calculate`, 'POST');
        return response.status;
    }

    async updateRecommendation(reco, isDraft) {
        const dto = {
            resolutionComments: reco.resolutionComments,
            entities: reco.entities,
            isDraft: isDraft,
            isAffected: reco.isAffected
        };

        const response = await this.sendRequest(`${this.baseUrl}/audits/${reco.auditId}/recos/${reco.id}`, 'POST', dto)
        return response;
    }

    async launchAudit(auditId) {
        const response = await this.sendRequest(`${this.baseUrl}/audits/${auditId}/start`, 'POST');
        return response.status;
    }

    async abandonAudit(auditId) {
        await this.sendRequest(`${this.baseUrl}/audits/${auditId}/abandon`, 'POST');
    }

    async getRecommendationDetails(auditId, recoId) {
        const response = await this.sendRequest(`${this.baseUrl}/audits/${auditId}/recos/${recoId}`);
        return await response.json();
    }

    getSeverityColor(severity) {
        if(severity != null){
            switch (severity.toUpperCase()) {
                case "MEDIUM":
                    return "orange darken-2";
                case "HIGH":
                    return "red darken-2";
                case "LOW":
                    return "green darken-2";
                default:
                    break;
            }
        }
    }

    hasRecoNotEvaluated(audit) {
        var recos = audit.categories
            .map(x => x.recommendations)
            .filter(x => x.length > 0);
        return [].concat.apply([], recos).filter(x => x.isAffected == null && (x.isAutomatic || x.totalEntities > 0)).length > 0;
    }

    hasAutomaticRecoInCalculation(audit) {
        var recos = audit.categories
            .map(x => x.recommendations)
            .filter(x => x.length > 0);

        return [].concat.apply([], recos).filter(x => x.isAutomatic && x.isAffected == null).length > 0;
    }

    async generateReport(uri){
        const response = await this.sendRequest(`${this.baseUrl}/${uri}`);
        return response;
    }
}

