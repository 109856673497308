<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div>Severity</div>
        <div class="font-weight-medium">
          <v-icon
            :color="getSeverityColor(recommendation.impact)"
            class="severity-icon"
            >mdi-alert-octagon</v-icon
          >
          <span style="vertical-align: middle" class="ml-1">{{
            recommendation.impact
          }}</span>
        </div>
      </v-col>
      <v-col>
        <div>Implementation complexity</div>
        <div class="font-weight-medium">
          <v-icon
            :color="getSeverityColor(recommendation.implementationComplexity)"
            class="severity-icon"
            >mdi-square-rounded</v-icon
          >
          <span style="vertical-align: middle" class="ml-1">{{
            recommendation.implementationComplexity
          }}</span>
        </div>
      </v-col> </v-row
    ><v-divider class="my-5"></v-divider>
    <v-row>
      <v-col>
        <div class="text-h6">Description</div>
        <div class="text-body-2">
          {{ recommendation.description }}
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col>
        <div class="text-h6">Remediation steps</div>
        <div class="text-body-2" v-html="recommendation.remediationSteps"></div>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row v-if="recommendation.isFree"> <!-- Not for BETA version  && recommendation.isAutomatic" --> 
      <v-col cols="col-12">
        <v-alert type="warning" class="ma-3" :value="true">
          <span class="font-weight-bold"> This audit is free </span>
          <span class="caption">
            Upgrade to Basic or Premium to see affected resources
          </span>
        </v-alert>
      </v-col>
    </v-row>
    <v-row><!-- Not for BETA version v-if="!recommendation.isFree || !recommendation.isAutomatic"-->
      <v-col
        v-if="recommendation.entities && recommendation.entities.length > 0"
      >
        <div class="text-h6">Affected resources</div>
        <v-data-table
          dense
          :headers="headers"
          :items="recommendation.entities"
          :search="search"
          hide-default-header
          disable-pagination
          hide-default-footer
          fixed-header
          height="300px"
        >
          <!-- HEADERS-->
          <template v-slot:header="{ props: { headers } }" >
            <thead>
                <tr>
                  <th v-for="h in headers" :key="h.value" :class="h.class">
                    <div :class="h.bgclass">
                      <span class="manual-span-header">{{h.text}}</span>
                    </div>
                  </th>
                </tr>
            </thead>
          </template>

          <template v-slot:item.name="{ item }">
            <img
              style="vertical-align: middle"
              class="mr-2"
              :src="getUrl(item.type)"
            />
            <a :href="item.resourceUrl" target="_blank">{{ item.name }}</a>
          </template>
          <template v-slot:item.resourceGroupName="{ item }">
            <img
              style="vertical-align: middle"
              class="mr-2"
              src="/Resource group.svg"
            />
            <a :href="item.resourceGroupUrl" target="_blank">{{
              item.resourceGroupName
            }}</a>
          </template>
          <template v-slot:item.isAffected="{ item }">
            <div :class="(isRecoAutomatic() || isAuditDone()) ? '' : 'manual-td'">
              <tr>
                <td >
                  <v-checkbox
                    v-model="item.isAffected"
                    color="red"
                    :indeterminate="item.isAffected == null"
                    :label="getCheckboxLabel(item.isAffected)"
                    :class="isRecoAutomatic() ? '' : getCheckboxStyle(item.isAffected)"
                    :disabled="isRecoAutomatic() || isAuditDone()"
                  ></v-checkbox>
                </td>
              </tr>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="recommendation.entities && recommendation.entities.length == 0"
      >
        <v-checkbox
          v-model="recommendation.isAffected"
          color="red"
          :indeterminate="recommendation.isAffected == null"
        >
          <template v-slot:label>
            Manual audit shows that resources are affected
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <v-textarea
          v-model="recommendation.resolutionComments"
          outlined
          name="input-7-4"
          label="Insert comments here"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuditService from "@/services/AuditService.js";

export default {
  data: () => ({
    recommendation: {},
    headers: [
      { text: "Resource name", value: "name" },
      { text: "Resource type", value: "type" },
      { text: "Resource group", value: "resourceGroupName" },
      { text: "Evaluation required", value: "isAffected"},
    ],
    staticHeaders: [
      { text: "Resource name", align: "start", filterable: false, value: "name" },
      { text: "Resource type", value: "type" },
      { text: "Resource group", value: "resourceGroupName" },
    ],
    affectedManualHeader: { text: "Evaluation required", value: "isAffected", class: "manual-header", bgclass:"manual-div-header"},
    affectedAutoHeader: { text: "Is Affected", value: "isAffected", class: "", bgclass:""},
    search: "",
    auditService: null,
  }),
  props: {
    recoId: Number,
    auditId: Number,
    auditStatus: String
  },
  created() {
    this.auditService = new AuditService(this.$route.params.accountName);
  },
  async mounted() {
    this.recommendation = await this.auditService.getRecommendationDetails(
      this.auditId,
      this.recoId
    );
    this.updateAffectationHeader(this.recommendation.isAutomatic);
  },
  watch: {
    recoId: async function (newVal) {
      if (newVal == 0) {
        this.recommendation = {};
        return;
      }
      this.recommendation = await this.auditService.getRecommendationDetails(
        this.auditId,
        newVal
      );
      this.updateAffectationHeader(this.recommendation.isAutomatic);
    },
  },
  methods: {
    async save() {
      const isDraft = false;
      await this.auditService.updateRecommendation(
        this.recommendation,
        isDraft
      );
    },
    async saveAsDraft() {
      const isDraft = true;
      await this.auditService.updateRecommendation(
        this.recommendation,
        isDraft
      );
    },
    getUrl(type) {
      return `/${type}.svg`;
    },
    getSeverityColor(severity) {
      return this.auditService.getSeverityColor(severity);
    },
    getCheckboxLabel(isAffected){
      if(isAffected == null){
        return "(not defined)"
      }
      else {
        return isAffected ? "Affected" : "Not affected"
      }
    },
    getCheckboxStyle(isAffected){
      return isAffected == null ? "checkbox-style-notdefined" : "checkbox-style-defined"; 
    },
    updateAffectationHeader(isAtomatic){
      if(this.headers.length > 0){
        this.headers = this.staticHeaders.concat((isAtomatic || this.isAuditDone()) ? this.affectedAutoHeader : this.affectedManualHeader);
      }
    },
    isRecoAutomatic(){
      return this.recommendation.isAutomatic;
    },
    isAuditDone(){
      return this.auditStatus === "Done";
    }
  },
};
</script>

<style scoped>
.manual-header {
  width: 200px;
  text-align: center  !important;
}
.manual-span-header{
  vertical-align: middle !important;
}
.manual-div-header {
  background-color: #BBDEFB !important;
  height: 30px;
  font-size: 17px;
  font-weight: bold;
  color:#1A237E;
}
.manual-td {
    background-color: #BBDEFB !important;
    width: 200px;
    border: 0px !important;
    padding: 0px !important;
}
.checkbox-style-defined{
  padding-left: 15px;
  font-weight: bold;
}
.checkbox-style-notdefined{
  padding-left: 15px;
  font-style: italic !important;
}
</style>