<template>
  <div class="text-center">
    <div class="mb-2">
      <v-progress-circular
        :size="130"
        :width="15"
        :value="value"
        :color="getChartColor(value)"
        class="font-weight-bold text-h6"
      >
        {{ value }}
      </v-progress-circular>
    </div>
    <div class="font-weight-bold">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: Number,
    title: String,
  },
  methods: {
    getChartColor(score) {
      if (score < 35) {
        return "#F44336";
      }

      if (score < 70) {
        return "#FD9F15";
      }

      return "#66B969";
    },
  },
};
</script>