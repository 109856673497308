<template>
  <v-app>
    <Navigation />
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-icon left>mdi-magnify-scan</v-icon>
                <span class="text-h6">Create new audit</span>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-alert
                  v-if="hasAuditsInProgress"
                  outlined
                  type="warning"
                  prominent
                  border="left"
                >
                  You already have an audit in progress
                </v-alert>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-text-field
                    v-model="auditName"
                    :rules="nameRules"
                    label="Audit Name"
                  ></v-text-field>
                  <v-btn :disabled="!valid" @click="launchAudit()"> Start audit </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import AuditService from "@/services/AuditService";
export default {
  components: {
    Navigation,
    Footer,
  },
  data: () => ({
    auditName: "",
    auditService: null,
    hasAuditsInProgress: null,
    valid: false,
    doesExist: null,
    nameRules: [
      v => !!v || 'Name is required'
    ],
  }),
  async mounted() {
    this.auditService = new AuditService(this.$route.params.accountName);

    this.hasAuditsInProgress = await this.auditService.hasAuditsInProgress();
  },
  methods: {
    async launchAudit() {
      if(this.$refs.form.validate()) {
        this.valid = false;
        const response = await this.auditService.createAudit(this.auditName);

        if(response.ok){
          let auditId = await response.text();
          await this.auditService.launchAudit(auditId);

          this.$router.push({
            name: "AuditsDetails",
            params: { auditId: auditId },
          }); 
        }else{
          this.valid = false;
          this.$refs.form.$children[0].errorBucket.push(await response.text());
        }
      }
    },
  },
};
</script>