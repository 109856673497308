import BaseService from "./BaseService";

export default class ServiceConnectionsService extends BaseService {
    async getServiceConnections() {
        return await this.sendRequest(`${this.baseUrl}/service-connections`, 'GET', null);
    }

    async getServiceConnection(serviceConnectionId) {
        return await this.sendRequest(`${this.baseUrl}/service-connections/${serviceConnectionId}`, 'GET');
    }

    async createServiceConnection(serviceConnection) {
        return await this.sendRequest(`${this.baseUrl}/service-connections`, 'POST', serviceConnection);
    }

    async updateServiceConnection(serviceConnection) {
        return await this.sendRequest(`${this.baseUrl}/service-connections/${serviceConnection.id}`, 'PUT', serviceConnection);
    }

    async deleteServiceConnection(serviceConnectionId) {
        return await this.sendRequest(`${this.baseUrl}/service-connections/${serviceConnectionId}`, 'DELETE');
    }
}

