<template>
  <v-app>
    <Navigation />
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-icon left>mdi-magnify-scan</v-icon>
                Audits
                <v-spacer></v-spacer>
                <v-btn
                  v-if="initComplete && hasServiceConnections"
                  small
                  color="success"
                  dark
                  to="audits/new"
                >
                  <v-icon left dark> mdi-plus </v-icon> Start new Audit
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-alert text outlined color="deep-orange" icon="mdi-fire">
              <b>Welcome to the Public Beta of the GreenOps app.</b><br />
              The recommendations presented here are based on our feedback
              experience after more than 10 years of experience on Azure and are
              not exhaustive. We are aware that some recommendations may be
              subject to debate, do not hesitate to give us your feedback via
              the red button in the bottom right corner of all screens.
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="initComplete && !hasServiceConnections">
          <v-col cols="12">
            <v-alert type="info" class="ma-3 info" :value="true">
              <span class="font-weight-bold">
                In order to launch an Audit, please create a Service
                Connection&nbsp;
                <router-link
                  to="settings"
                  style="text-decoration-color: white; color: white"
                >
                  Here </router-link
                >&nbsp;with read rights on the resources to be taken into
                account by the audit.
              </span>
            </v-alert>
          </v-col>
        </v-row>
        <v-container style="height: 700px" v-if="!initComplete">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Getting your audits
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="light-blue darken-2"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
        <v-row v-show="initComplete">
          <v-col cols="6">
            <v-card>
              <v-card-title> Latest audits </v-card-title>
              <v-divider class="mb-3"></v-divider>
              <v-simple-table style="max-height: 365px; overflow-y: auto">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">Date</th>
                      <th class="text-left">Score</th>
                      <th width="50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="audit in audits" :key="audit.id">
                      <td>{{ audit.name }}</td>
                      <td>
                        <v-icon :color="getStatusColor(audit.status)">{{
                          getStatusIcon(audit.status)
                        }}</v-icon>
                      </td>
                      <td>{{ audit.dateAsText }}</td>
                      <td>
                        <span :class="getColorClass(audit.score)">
                          {{ audit.score }}
                        </span>
                        / 100
                      </td>
                      <th>
                        <v-btn icon :to="getAuditDetailsUrl(audit.id)">
                          <v-icon> mdi-magnify </v-icon>
                        </v-btn>
                      </th>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title> Audit History </v-card-title>
              <v-divider class="mb-3"></v-divider>
              <audit-history-chart
                :audits="audits.filter((a) => a.status === 'Done')"
              ></audit-history-chart>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-show="initComplete">
          <v-col col="12">
            <v-card>
              <v-card-title> Latest completed audit results </v-card-title>
              <v-divider class="mb-3"></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="3" v-for="score in scores" :key="score.title">
                    <audit-category-chart
                      :value="score.score"
                      :title="score.title"
                    ></audit-category-chart>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import AuditService from "@/services/AuditService";
import ServiceConnectionsService from "@/services/ServiceConnectionsService";
import Navigation from "@/components/Navigation.vue";
import AuditHistoryChart from "@/components/Audit/AuditHistoryChart.vue";
import AuditCategoryChart from "@/components/Audit/AuditCategoryChart.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Audits",
  components: {
    Navigation,
    AuditHistoryChart,
    AuditCategoryChart,
    Footer,
  },
  data: () => ({
    audits: [],
    hasServiceConnections: null,
    scores: [],
    initComplete: false,
  }),
  async mounted() {
    const auditService = new AuditService(this.$route.params.accountName);
    const serviceConnectionService = new ServiceConnectionsService(
      this.$route.params.accountName
    );

    let [auditsReponse, serviceConnectionsResponse] = await Promise.all([
      auditService.getAudits(),
      serviceConnectionService.getServiceConnections(),
    ]);
    this.audits = await auditsReponse.json();
    this.hasServiceConnections =
      (await serviceConnectionsResponse.json()).length > 0;

    const latestCompletedAudit = this.audits.filter(
      (audit) => audit.status === "Done"
    )[0];

    if (latestCompletedAudit !== undefined) {
      for (let i = 0; i < latestCompletedAudit.categories.length; i++) {
        this.scores.push({
          title: latestCompletedAudit.categories[i].name,
          score: Math.floor(latestCompletedAudit.categories[i].score * 10) / 10,
        });
      }
    }

    this.initComplete = true;
  },
  methods: {
    getColorClass: (value) => {
      if (value < 35) {
        return "red--text font-weight-bold";
      }

      if (value < 70) {
        return "orange--text font-weight-bold";
      }

      return "green--text font-weight-bold";
    },
    getStatusIcon(value) {
      if (value === "New") {
        return "mdi-new-box";
      }
      if (value === "InProgress") {
        return "mdi-autorenew";
      }
      if (value === "Failed") {
        return "mdi-close-circle-outline";
      }
      return "mdi-check";
    },
    getStatusColor(value) {
      if (value === "Done") {
        return "green";
      }
    },
    getAuditDetailsUrl(id) {
      return `/${this.$route.params.accountName}/audits/${id}`;
    },
  },
};
</script>

<style lang="scss">
main {
  background-color: #efeff9;
}
</style>