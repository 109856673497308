<template>
  <v-app>
    <Navigation />
    <v-main>
      <v-container fluid>
        <v-container style="height: 700px" v-if="!audit">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Getting audit details
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="light-blue darken-2"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
        <template v-if="audit && audit.categories">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <v-icon left>mdi-magnify-scan</v-icon>
                  <span class="text-h6">{{ audit.name }}</span>
                </v-card-title>
                <v-card-subtitle v-if="audit"
                  >Audit started on {{ audit.date }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-alert text outlined color="deep-orange" icon="mdi-fire">
                <b>Welcome to the Public Beta of the GreenOps app.</b><br />
                The recommendations presented here are based on our feedback
                experience after more than 10 years of experience on Azure and
                are not exhaustive. We are aware that some recommendations may
                be subject to debate, do not hesitate to give us your feedback
                via the red button in the bottom right corner of all screens.
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="audit.score">
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <span class="text-h6">Score : {{ audit.score }} / 100</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col cols="3" v-for="score in scores" :key="score.title">
                      <audit-category-chart
                        :value="score.score"
                        :title="score.title"
                      ></audit-category-chart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card v-if="scoreCalculationInProgress && !audit.score">
                <v-card-title>
                  <!-- <v-icon left>mdi-magnify-scan</v-icon> -->
                  <template>
                    <span class="mx-auto">Compute in progress ...</span>
                    <v-progress-linear
                      color="light-blue darken-2"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>
                  </template>
                </v-card-title>
              </v-card>
              <v-alert
                v-if="shouldDisplayManualEvalWarning"
                type="warning"
                class="ma-3"
                :value="true"
              >
                <span class="font-weight-bold">
                  Please complete manual evaluations below.
                </span>
              </v-alert>
              <v-alert
                v-if="shouldDisplayFinishInfo"
                type="info"
                class="ma-3 info"
                :value="true"
              >
                <span class="font-weight-bold">
                  Scroll down and click on FINISH AUDIT to calculate your
                  scores.
                </span>
              </v-alert>
              <v-alert
                v-else-if="shouldDisplayPoolingInfo"
                type="info"
                class="ma-3 info"
                :value="true"
              >
                <span class="font-weight-bold">
                  This may take a few minutes, please wait.
                </span>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <template v-for="category in audit.categories">
                <v-card
                  :key="category.id"
                  v-if="
                    category.recommendations &&
                    category.recommendations.length > 0
                  "
                  class="mb-3 mx-auto"
                >
                  <v-card-title>
                    <span class="text-h6 font-weight-light">
                      {{ category.name }}</span
                    >
                  </v-card-title>
                  <v-card-subtitle>
                    {{ category.description }}
                  </v-card-subtitle>
                  <v-divider class="mb-3"></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Name</th>
                          <th class="text-right icon"> </th>
                          <th class="text-center severity">Severity</th>
                          <th class="text-left is-automatic">Is Automatic</th>
                          <th class="text-left state">Resources</th>
                        </tr>
                      </thead>
                      <tbody
                        v-if="
                          category.recommendations &&
                          category.recommendations.length > 0
                        "
                      >
                        <tr
                          v-for="reco in category.recommendations"
                          :key="reco.id"
                        >
                          <td>{{ reco.recommendationName }}</td>
                          <td>
                            <v-icon
                              v-if="reco.isAffected != null && reco.totalEntities > 0 && reco.affectedEntities > 0"
                              :color="getSeverityColor(reco.impact)"
                              class="severity-icon"
                              >mdi-alert-octagon</v-icon
                            >
                          </td>
                          <td class="text-center">{{reco.impact}}</td>
                          <td>
                            <v-simple-checkbox
                              v-model="reco.isAutomatic"
                              hide-details
                              :ripple="false"
                              disabled
                              readonly
                              class="checkbox-is-automatic"
                            ></v-simple-checkbox>
                          </td>
                          <td>
                            <div
                              v-if="reco.isAutomatic && reco.isAffected == null"
                              style="width: 50px; margin-left: 15px"
                            >
                              <div class="snippet" data-title=".dot-pulse">
                                <div class="stage">
                                  <div
                                    v-if="audit.status != 'Failed'"
                                    class="dot-pulse"
                                  ></div>
                                </div>
                              </div>
                            </div>

                            <div v-if="!shouldDisableViewDetails">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <span
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="!reco.isAutomatic && reco.isAffected == null && reco.totalEntities != 0"
                                      class="
                                        blue--text
                                        text--darken-4
                                        font-weight-black
                                        cursor-pointer
                                      "
                                      @click="viewDetails(reco)"
                                    >
                                      Evaluation required
                                  </span>
                                </template>
                                <span>Please wait until resources are loaded</span>
                              </v-tooltip>
                            </div>
                            <div v-if="shouldDisableViewDetails">
                            <span
                              v-if="!reco.isAutomatic && reco.isAffected == null && reco.totalEntities != 0"
                                class="
                                  blue--text
                                  text--darken-4
                                  font-weight-black
                                  cursor-pointer
                                "
                                @click="viewDetails(reco)"
                              >
                                Evaluation required
                            </span>
                            </div>
                            <span 
                              v-if="reco.isAffected != null && reco.totalEntities > 0"
                              class="
                                  blue--text
                                  text--darken-2
                                  cursor-pointer
                                "
                              @click="viewDetails(reco)"
                            >
                              {{ reco.affectedEntities }} /
                              {{ reco.totalEntities }}
                            </span>
                            <span v-if="reco.isAffected && reco.totalEntities == 0">
                              Affected
                            </span>
                            <span v-if="reco.isAffected != null && !reco.isAffected && reco.totalEntities == 0">
                              Not affected
                            </span>

                            <span v-if="!reco.isAutomatic && reco.isAffected == null && reco.totalEntities == 0">
                              <span v-if="shouldDisplayPoolingInfo">
                                <div style="width: 50px; margin-left: 15px">
                                  <div class="snippet" data-title=".dot-pulse">
                                    <div class="stage">
                                      <div
                                        v-if="audit.status != 'Failed'"
                                        class="dot-pulse"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                              <span v-else>
                                Not affected
                              </span>
                            </span>

                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="success"
                class="mx-2"
                v-if="audit.status !== 'Done'"
                @click="markAsDone(audit.id)"
                :disabled="scoreCalculationInProgress || hasRecoNotEvaluated()"
              >
                <v-icon dark left> mdi-check-bold </v-icon>
                Finish Audit
              </v-btn>
              <v-btn
                color="cyan"
                dark
                class="mx-2"
                @click="downloadAuditReport()"
              >
                <v-icon dark left> mdi-file-excel </v-icon>
                Generate audit report
              </v-btn>
              <v-btn
                color="error"
                class="mx-2"
                @click="dialogDeleteAudit = true"
              >
                <v-icon dark left> mdi-delete </v-icon>
                Delete audit
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <v-dialog v-model="failed" width="500">
        <v-card>
          <v-card-title class="text-h5 red lighten-2 justify-center">
            <v-icon dark class="mr-2" x-large>mdi-close-circle-outline</v-icon>
          </v-card-title>

          <v-card-text class="text-center">
            <h2 class="my-5">Ooops !</h2>
            Something went wrong. Please check your service connection and
            retry, if the problem persists contact the
            <a target="_blank" href="https://expertime.com/contactez-nous/"
              >assistance</a
            >
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="failed = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDeleteAudit" width="500" v-if="this.audit">
        <v-card>
          <v-card-title>
            <span class="text-h5"> Delete {{ this.audit.name }} </span>
          </v-card-title>

          <v-card-text> Do you really want delete this audit ? </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error darken-1"
              text
              @click="dialogDeleteAudit = false"
            >
              Cancel
            </v-btn>
            <v-btn color="error darken-1" @click="abandonAudit()">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="drawer" persistent x-large>
        <v-card>
          <v-toolbar elevation="1">
            <v-toolbar-title>
              {{ selectedRecommendationTitle }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text @click="drawer = false"> Close </v-btn>
              <v-btn text @click="saveAsDraft()"> Save As Draft </v-btn>
              <v-btn text @click="saveDetails()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <recommendation-details
            style="max-height: 70vh; overflow: auto"
            v-if="selectedRecommendationId > 0"
            :recoId="selectedRecommendationId"
            :auditId="parseInt(audit.id)"
            :auditStatus="this.audit.status"
            ref="recoDetails"
          ></recommendation-details>
        </v-card>
      </v-dialog>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import AuditService from "@/services/AuditService";
import Navigation from "@/components/Navigation.vue";
import RecommendationDetails from "@/components/Audit/RecommendationDetails.vue";
import AuditCategoryChart from "@/components/Audit/AuditCategoryChart.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navigation,
    RecommendationDetails,
    AuditCategoryChart,
    Footer,
  },
  data: () => ({
    audit: null,
    drawer: false,
    selectedRecommendationId: 0,
    selectedRecommendationTitle: null,
    polling: null,
    auditService: null,
    auditReportUrl: null,
    scoreCalculationInProgress: false,
    scores: [],
    failed: false,
    auditIsPooling: false,
    dialogDeleteAudit: false,
    poolingDelay: 10000,
  }),
  computed: {
    getContactUrl() {
      return `/${this.$route.params.accountName}/contact`;
    },
    shouldDisplayPoolingInfo() {
      return this.auditIsPooling;
    },
    shouldDisplayManualEvalWarning() {
      return (
        this.audit.status !== "Failed" &&
        this.audit.categories.filter(
          (cat) =>
            cat.recommendations.filter(
              (reco) => !reco.isAutomatic && reco.isAffected == null && reco.totalEntities != 0
            ).length > 0
        ).length > 0
      );
    },
    shouldDisplayFinishInfo() {
      return (
        !(this.scoreCalculationInProgress || this.hasRecoNotEvaluated()) &&
        this.audit.status !== "Done"
      );
    },
    shouldDisableViewDetails(){
      let recoId = this.selectedRecommendationId;
      let SelectedRecommendation = this.audit.categories.find(cat => cat.recommendations).recommendations.find(reco => reco.id == recoId);
      if (SelectedRecommendation == undefined || SelectedRecommendation.totalEntities == undefined)
        return true;
      return SelectedRecommendation.totalEntities > 0;
    }
  },
  methods: {
    viewDetails(reco) {
      this.selectedRecommendationId = reco.id;
      this.selectedRecommendationTitle = reco.recommendationName;
      if (this.shouldDisableViewDetails){
        this.drawer = true;
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    async saveAsDraft() {
      await this.$refs.recoDetails.saveAsDraft();
      this.drawer = false;
      this.selectedRecommendationId = 0;
      await this.refreshAudit();
    },
    async saveDetails() {
      await this.$refs.recoDetails.save();
      this.drawer = false;
      this.selectedRecommendationId = 0;
      await this.refreshAudit();
    },
    async refreshAudit() {
      this.audit = await this.auditService.getAudit(this.$route.params.auditId);
    },
    async abandonAudit() {
      this.audit = await this.auditService.abandonAudit(
        this.$route.params.auditId
      );
      this.$router.push({
        path: `/${this.$route.params.accountName}/audits`,
      });
    },
    runPollingData() {
      this.polling = setInterval(async () => {
        await this.pollData();
      }, this.poolingDelay);
    },
    async pollData() {
      if (
        (this.audit.status != "InProgress" && this.audit.status != "New") ||
        !this.auditService.hasAutomaticRecoInCalculation(this.audit)
      ) {
        this.failed = this.audit.status === "Failed";
        this.auditIsPooling = false;
        clearInterval(this.polling);
      }
      await this.refreshAudit();
    },
    getSeverityColor(severity) {
      return this.auditService.getSeverityColor(severity);
    },
    hasRecoNotEvaluated() {
      return this.auditService.hasRecoNotEvaluated(this.audit);
    },
    async downloadAuditReport() {
      var res = await this.auditService.generateReport(this.auditReportUrl);

      const blob = new Blob([await res.text()], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "report.csv";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    markAsDone(auditId) {
      this.scoreCalculationInProgress = true;
      window.scrollTo(0, 0);
      this.polling = setInterval(async () => {
        if (this.audit.status === "Done") {
          this.scores = [];
          this.updateScores();
          document.getElementById("app").scrollIntoView({ behavior: "smooth" });
          clearInterval(this.polling);
        }

        await this.refreshAudit();
      }, 2000);

      return this.auditService.launchAuditCalculation(auditId);
    },
    updateScores() {
      for (let i = 0; i < this.audit.categories.length; i++) {
        if (
          this.audit.categories[i].recommendations.length > 0 ||
          this.audit.isFree
        ) {
          this.scores.push({
            title: this.audit.categories[i].name,
            score: Math.floor(this.audit.categories[i].score * 10) / 10,
          });
        }
      }
    },
  },
  async mounted() {
    this.auditService = new AuditService(this.$route.params.accountName);
    await this.refreshAudit();
    this.updateScores();

    this.failed = this.audit.status === "Failed";

    this.auditReportUrl = `audits/${this.audit.id}/report`;
    if (this.audit.status == "InProgress" || this.audit.status == "New") {
      this.auditIsPooling = this.auditService.hasAutomaticRecoInCalculation(
        this.audit
      );
      this.runPollingData();
    } else {
      this.auditIsPooling = false;
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>

<style lang="scss" scoped>
th.state {
  width: 250px;
}

th.severity {
  width: 150px;
}

th.icon {
  width: 50px;
}

th.is-automatic {
  width: 150px;
}

.checkbox-is-automatic {
  display: inline;
}

.cursor-pointer:hover {
  cursor: pointer;
}
</style>