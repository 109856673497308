<template>
  <apexchart
    ref="chart"
    type="line"
    height="350"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    audits: Array,
  },
  watch: {
    audits: async function (newVal) {
      this.$refs.chart.updateOptions({
        series: [
          {
            data: newVal.map((a) => a.score).reverse(),
          },
        ],
        labels: newVal.map((a) => a.dateAsText).reverse(),
      });
    },
  },
  data: () => ({
    series: [
      {
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        id: "areachart-2",
      },
      dataLabels: {
        enabled: true,
      },
      annotations: {
        yaxis: [
          // {
          //   y: 68,
          //   borderColor: "#00E396",
          //   label: {
          //     borderColor: "#00E396",
          //     style: {
          //       color: "#fff",
          //       background: "#00E396",
          //     },
          //     text: "Eco-Score median SMB",
          //   },
          // },
        ],
      },
      stroke: {
        curve: "straight",
      },
      labels: [],
      xaxis: {
        type: "date",
      },
      yaxis: {
        min: 0,
        max: 100,
      },
      colors: ["#f56942"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["#42f55d"],
          shadeIntensity: 1,
          type: "vertical",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
    },
  }),
};
</script>